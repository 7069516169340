<template>
    <a href="/ai-face-style-detection" class="container" ref="container">

        <l-video class="video" src="https://static.ycsd.work//site/1.mp4"></l-video>

        <div class="marbles-container">

            <l-animate animate="fadeInUp" class="m40 op0">
                <span class="marbles small">变美从了解自己开始</span>
            </l-animate>

            <l-animate animate="fadeInUp" delay="0.5" class="m35 op0">
                <span class="marbles small">人脸拍照，即时输出专属穿搭报告</span>
            </l-animate>

            <l-animate animate="fadeInUp" delay="1" class="m40 op0">
                <span class="marbles">颜创大数据人工智能算法</span>
            </l-animate>

            <l-animate animate="fadeInUp" delay="1.5" class="m48 op0">
                <span class="marbles">全身1000+点数据分析</span>
            </l-animate>

            <l-animate animate="fadeInUp" delay="2" class="op0">
                <span class="marbles small">让每个人轻松找到提升自己衣品的风格着装</span>
            </l-animate>

        </div>
    </a>
</template>

<script>
    import LAnimate from "../../../components/LAnimate";
    import LVideo from "../../../components/LVideo";

    export default {
        name: "Banner1",
        components: {LVideo, LAnimate},
        mounted() {
        },
        methods: {
            play() {
                this.$children.map((child) => {
                    if ((typeof child.play) === 'function') {
                        child.play()
                    }
                });
            },
            stop() {

                this.$children.map((child) => {
                    if ((typeof child.stop) === 'function') {
                        child.stop()
                    }
                });
            },
        }
    }
</script>

<style lang="less" scoped>
    .op0 {
        opacity: 0;
    }

    .container {
        height: 100%;
        padding-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .marbles.small {
        font-size: 20px;
        line-height: 28px;
        padding: 10px 30px;
    }

    .video {
        min-width: 100%;
        min-height: 100%;
    }

    .marbles-container {
        position: absolute;
        right: 20%;
        top: 25%;
        text-align: left;

        .m35 {
            margin-bottom: 35px
        }

        .m40 {
            margin-bottom: 40px
        }

        .m48 {
            margin-bottom: 48px
        }
    }

    .marbles {
        display: inline-block;
        box-shadow: 0 2px 50px 0 #000000;
        border-radius: 32px;
        color: #F9F9F9;
        font-size: 24px;
        line-height: 33px;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 12px 30px;
    }
</style>