<template>
    <div class="container">
        <l-video class="video" src="https://static.ycsd.work//site/b5.mp4"></l-video>
    </div>
</template>

<script>
    import LVideo from "../../../components/LVideo";
    export default {
        name: "Banner5",
        components: {LVideo}
    }
</script>

<style lang="less" scoped>
    .container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .video {
        min-width: 100%;
        min-height: 100%;
    }
</style>