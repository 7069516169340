<template>
    <div class="l-icp">
        <a href="https://beian.miit.gov.cn/">蜀ICP备2021007621号-2</a>
    </div>
</template>

<script>
    export default {
        name: "LIcp"
    }
</script>

<style lang="less" scoped>
    .l-icp {
        height: 64px;
        font-size: 16px;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 64px;
        background-color: rgba(16, 16, 16, 1);

        a {
            color: #F9F9F9;
        }
    }

    #cnzz_stat_icon_1280354623{
        margin-left: 20px;
    }
</style>