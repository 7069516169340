<template>
    <video ref="video" muted loop autoplay="autoplay" :src="src"></video>
</template>

<script>
    export default {
        name: "LVideo",
        props: {
            src: String
        },
        mounted() {

            setTimeout(() => {
                this.play()
            }, 500)

        },
        methods: {
            play() {
                if (!this.$refs['video'].paused) return
                this.$refs['video'].play()
            }
        }
    }
</script>

<style scoped>

</style>