import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'ant-design-vue/dist/antd.css';

import './assets/css/animate.min.css';
import './assets/css/layout.css';

import 'amfe-flexible';
import './helper';
import Antd from 'ant-design-vue';
import MetaInfo from 'vue-meta-info';


Vue.config.productionTip = false

Vue.use(Antd);

Vue.use(MetaInfo);


new Vue({
    router,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
