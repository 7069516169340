<template>
    <div class="container">
        <div class="left-text">
            <div class="t1">颜创AI星推官</div>
            <div class="t2">
                <img class="img" src="@/assets/images/index/t4.png" alt="">
            </div>
            <div class="t3">
                如果你热爱时尚穿搭，如果你是时尚媒体人士、网红达人或者你线下有各类美业相关的客户群体更或者你想让AI时尚穿搭技术让周围人穿搭 更省时高效衣品好那么请加入我们，成为颜创AI星推官利用碎片时间也能轻松坐享收益
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Banner4"
    }
</script>

<style lang="less" scoped>
    .container {
        background: url("https://static.ycsd.work/site/fashion/b4.png") no-repeat center;
        background-size: cover;
        height: 100%;

        &:after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 0;
        }
    }

    .left-text {
        position: absolute;
        top: 27.59%;
        left: 12.81%;
        text-align: left;
        color: #FFFFFF;
        z-index: 1;
    }

    .t1 {
        width: 475px;
        height: 112px;
        font-size: 80px;
        font-weight: 500;
        line-height: 112px;
        position: relative;
        z-index: 1;
        margin-bottom: 46px;

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: 305px;
            height: 23px;
            background: #1F7762;
            bottom: 22px;
            right: -72px;
            z-index: -1;
        }
    }

    .t2 {
        width: 835px;
        padding-bottom: 59px;

        img {
            max-width: 100%;
        }
    }

    .t3 {
        border-top: 1px solid rgba(255, 255, 255, 1);
        padding-top: 30px;
        width: 1139px;
        height: 64px;
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 32px;
    }


</style>