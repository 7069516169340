<template>
    <div :class="animateClass" :style="{'animation-delay':`${delay}s`}">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "l-animate",
        props: {
            delay: {
                default: 0,
            },
            autoplay: {
                type: Boolean,
                default: false
            },
            animate: String,
        },
        data() {
            return {
                animateClass: ''
            }
        },
        mounted() {

            if (this.autoplay) {
                this.play()
            }
        },
        methods: {

            play() {
                this.animateClass = ' animated ' + this.animate;
            },

            stop() {
                this.animateClass = '';
            },
        }
    }
</script>

<style scoped>

</style>