<template>
    <a href="/quantitative-fashion-institute" class="container">
        <l-video class="video" src="https://static.ycsd.work/site/b22.mp4"></l-video>

        <div class="left-text">
            <div class="title-img">
                <img class="img" src="@/assets/images/index/t3.png" alt="">
            </div>
            <div class="t1">AI人工智能</div>
            <div class="t2">
                <span>量化时尚算法</span>
            </div>
            <div class="t3">YOCHUR量化时尚学院，零学费开启“时髦吸金”新职业</div>
            <div class="t4">量化时尚学院</div>
        </div>
    </a>
</template>

<script>
    import LVideo from "../../../components/LVideo";
    export default {
        name: "Banner3",
        components: {LVideo}
    }
</script>

<style scoped>
    .container {
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    .video{
        min-width: 100%;
        min-height: 100%;
    }
    .left-text {
        position: absolute;
        top: 29.44%;
        right: 50%;
        color: rgba(249, 249, 249, 1);
        text-align: left;
    }

    .title-img {
        width: 659px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(249, 249, 249, 1);
        margin-bottom: 20px;
    }

    .t1, .t2 {
        font-size: 64px;
    }

    .t2 span {
        display: inline-block;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(249, 249, 249, 1);
    }

    .t2 {
        margin-bottom: 24px;
    }

    .t3 {
        font-size: 16px;
        margin-bottom: 9px;
    }

    .t4{
        font-size: 100px;
    }


</style>