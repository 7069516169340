let metaObj = {
    title: '颜创AI|颜创时代科技|颜创AI检测|颜创时代',
    meta: [{
        name: 'keyWords',
        content: '形象管理课程|成都形象管理陪训|时尚穿搭培训|穿衣搭配网|服装搭配技巧|穿搭技巧指南|时尚造型穿搭师|时尚穿衣搭配|My衣橱管家|衣橱管家|云衣橱|服饰搭配'
    }, {
        name: 'description',
        content: '颜创时代官网是科技与培训时尚穿衣搭配网站，拥有全新AI形象风格检测技术帮助你你更好的了解自己所需，给到你专业的穿搭技巧指南。在线下我们开设有成都形象管理陪训、时尚穿搭培训。通过形象管理课程让您学会各种风格服装搭配技巧，帮助你快速成为时尚造型穿搭师，掌握时尚穿衣搭配。与你共享无国界最IN时尚穿搭！'
    }],
}

const appendTitle = function (keywords) {

    metaObj.title = keywords + ' - ' + metaObj.title

    return metaObj
}


const meta = function () {
    return metaObj
}

export default {
    appendTitle, meta
}