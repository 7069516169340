Array.prototype.random = function () {

    let ran = (parseInt(Math.random() * 10)) % this.length;

    return this[ran];
}

Array.prototype.lastRandom = null;

let diffRandom = function (array) {

    if (!array.length) {
        throw 'must be array';
    }

    this.base = array
    this.list = []

    this.reset()
}

diffRandom.prototype.reset = function () {
    this.list = JSON.parse(JSON.stringify(this.base))
}

diffRandom.prototype.random = function () {

    if (!this.list.length) this.reset();

    let index = (parseInt(Math.random() * 10)) % this.list.length;
    let res = this.list[index]

    this.list.splice(index, 1);

    return res;
}


Array.prototype.diffRandom = function () {

    if (!(this.lastRandom) ) {
        this.lastRandom = new diffRandom(this);
    }

    return this.lastRandom.random();
}

