<template>
    <div class="full-height">
        <main-nav class="nav-fixed" ref="nav"></main-nav>

        <div id="index-banners" class="">

            <div ref="index-swiper" class="swiper-container">
                <div class="swiper-wrapper">

                    <div class="swiper-slide">
                        <banner1 ref="banner1"></banner1>
                    </div>
                    <div class="swiper-slide">
                        <banner5 ref="banner2"></banner5>
                    </div>
                    <div class="swiper-slide">
                        <banner3 ref="banner3"></banner3>
                    </div>
                    <div class="swiper-slide">
                        <banner4 ref="banner4"></banner4>
                    </div>

                </div>
                <!-- 如果需要分页器 -->
                <div class="swiper-pagination"></div>
            </div>


        </div>

        <div class="icp-container">
            <l-icp class="icp"></l-icp>
        </div>
    </div>
</template>

<script>

    import MainNav from "../../components/MainNav";
    import 'swiper/swiper-bundle.min.css'
    import Swiper from 'swiper/swiper-bundle.min'

    import Banner1 from "./Banners/Banner1";
    import Banner3 from "./Banners/Banner3";
    import Banner4 from "./Banners/Banner4";
    import LIcp from "../../components/LIcp";
    import meta from "../../meta";
    import Banner5 from "./Banners/Banner5";

    export default {
        name: 'Index',
        components: {
            Banner5,
            LIcp,
            Banner4,
            Banner3,
            Banner1,
            MainNav,
        },
        data() {
            return {
                current: 'index',
                swiperOptions: {
                    direction: 'vertical',
                    autoplay: {
                        delay: 5000,
                    },
                    mousewheel: true,
                    // loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    on: {
                        slideChangeTransitionEnd: this.slided
                    },
                },
                swiper: null,
                lastBanner: null,
            }
        },
        metaInfo: meta.meta(),
        mounted() {
            this.initSwiper()
        },
        methods: {
            initSwiper() {
                this.swiper = new Swiper(this.$refs['index-swiper'], this.swiperOptions);
                this.lastBanner = this.$refs['banner1']
                this.lastBanner.play()
            },
            slided(swiper) {

                let index = swiper.activeIndex + 1;

                if (index > 4) index = 1;
                if (index < 1) index = 4;


                let last = this.lastBanner;
                this.lastBanner = this.$refs['banner' + index];


                if ((typeof this.lastBanner.play) === 'function') {
                    this.lastBanner.play();
                }

                if (last && ((typeof last.stop) === 'function')) {
                    last.stop();
                }


            },
        }
    }
</script>
<style lang="less">

    #index-banners {
        background-color: rgba(16, 16, 16, 1);
        height: 100%;
        overflow: hidden;
        position: relative;

        .swiper-container {
            height: 100%;
        }

        .swiper-slide {
            overflow: hidden;
        }

        .swiper-pagination {
            right: 100px;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 50%;
        }

        .swiper-pagination-bullet-active {
            background-color: white;
        }
    }

    .icp-container {
        position: fixed;
        left: 0;
        width: 100%;
        bottom: 0;
        z-index: 9;
        background-color: rgba(146, 146, 146, 0.1);
    }

    .icp {
        background-color: transparent !important;
    }

</style>

<style>
    .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 10px;
    }
</style>