import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "../views/Index/Index";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Index
    }, {
        path: '/my-wardrobes',
        component: () => import('../views/Wardrobe/Index')
    },
    {
        path: '/ai-clothing-detection',
        name: 'ai-clothing-detection',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Ai/ClothingDetection')
    }, {
        path: '/ai-face-style-detection',
        name: 'ai-face-style-detection',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Ai/FaceStyleDetection')
    }, {
        path: '/quantitative-fashion-institute',
        name: 'quantitative-fashion-institute',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Fashion/QuantitativeFashion')
    }, {
        path: '/fashion-styling-dresser',
        name: 'fashion-styling-dresser',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Fashion/StylingDresser')
    }, {
        path: '/quantitative-fashion-manager',
        name: 'quantitative-fashion-manager',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Fashion/FashionManager')
    }, {
        path: '/news',
        name: 'news',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/News/Index')
    }, {
        path: '/news/:id',
        name: 'news-detail',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/News/Detail')
    }, {
        path: '/sign-up-process',
        name: 'sign-up-process',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Fashion/SignUp')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
