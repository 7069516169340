<template>
    <a-layout ref="nav" id="main-nav" :class="{dark:!(isLight),'nav-fixed':fixed}">
        <a-layout-header class="header">
            <div class="logo" @click="gotoUrl('/')">
                <img class="img logo-white" src="@/assets/logo.png" alt="">
                <img class="img logo-black" src="@/assets/logo_black.png" alt="">
            </div>

            <a-menu class="nav" mode="horizontal">

                <template v-for="(menu) in menus">
                    <a-menu-item v-if="!menu.children" :key="menu.key">
                        <router-link class="nav-link" :to="menu.path">
                            {{ menu.name}}
                        </router-link>

                    </a-menu-item>

                    <a-sub-menu v-else :key="menu.key">
                        <span slot="title" class="submenu-title-wrapper"
                              @click="gotoUrl(menu.path)">{{ menu.name}}</span>

                        <template v-for="(child) in menu.children">
                            <a-menu-item :key="child.key" @click="gotoUrl(child.path)">
                                <router-link :to="child.path">
                                    {{ child.name }}
                                </router-link>
                            </a-menu-item>
                        </template>

                    </a-sub-menu>

                </template>

            </a-menu>
        </a-layout-header>
    </a-layout>
</template>

<script>
    export default {
        name: "main-nav",
        props: {
            fixed: Boolean,
        },
        data() {
            return {
                isLight: true,
                themeFixed: false,

                menus: [{
                    key: 'index',
                    name: '首页',
                    path: '/',
                }, {
                    key: 'my-wardrobes',
                    name: 'My衣橱管家',
                    path: '/my-wardrobes',
                }, {
                    key: 'ai',
                    name: 'AI时尚检测',
                    path: '#',
                    children: [
                        {
                            key: 'ai-clothing-detection',
                            name: 'AI服饰检测',
                            path: '/ai-clothing-detection',
                        }, {
                            key: 'ai-face-style-detection',
                            name: 'AI形象风格检测',
                            path: '/ai-face-style-detection',
                        }
                    ],
                },
                    {
                        key: 'quantitative-fashion-institute',
                        name: '量化时尚学院',
                        path: '/quantitative-fashion-institute',
                        children: [
                            {
                                key: 'fashion-styling-dresser',
                                name: '时尚造型穿搭师',
                                path: '/fashion-styling-dresser',
                            }, {
                                key: 'quantitative-fashion-manager',
                                name: '量化时尚管理师',
                                path: '/quantitative-fashion-manager',
                            }
                        ],
                    }, {
                        key: 'news',
                        name: '颜创动态',
                        path: '/news',
                    }
                ]
            }
        },
        mounted() {
            this.listen()
        },
        destroyed() {
            this.unListen()
        },
        methods: {
            listen() {
                window.addEventListener('scroll', this.getScroll);
            },
            unListen() {
                window.removeEventListener('scroll', this.getScroll);
            },
            getScroll() {
                if (this.themeFixed) return;

                this.isLight = !(!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 300);
            },
            gotoUrl(url) {
                if (url === '#') return
                location.href = url
            },
            light() {
                this.themeFixed = true
                this.isLight = true
            },
            dark() {
                this.themeFixed = true
                this.isLight = false
            },
        },
    }
</script>

<style lang="less" scoped>
    #main-nav {
        background-color: rgba(146, 146, 146, 0.1);
        height: 70px;

        .logo-black {
            display: none;
        }
    }

    #main-nav .header {
        height: 70px;
        line-height: 70px;
    }

    #main-nav .nav {
        float: right;
        line-height: 70px;
        font-size: 18px;
    }


    #main-nav .logo {
        float: left;
        height: 28px;

        img {
            height: 100%;
        }
    }

    #main-nav.dark {
        background: #FFFFFF;
    }

</style>
<style lang="less">
    #main-nav {
        background-color: transparent;

        .header, .ant-menu {
            background-color: transparent;
            border-bottom: 0;
        }

        .submenu-title-wrapper, .ant-menu-submenu {
            color: #F9F9F9 !important;
        }

        .ant-menu-submenu-title {
            padding-left: 30px;
            padding-right: 30px;
        }

        .ant-menu-item {
            padding-left: 30px;
            padding-right: 30px;
            color: #F9F9F9 !important;
        }

        .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
            border-bottom: 0 !important;
        }
    }

    .ant-menu-sub .ant-menu-item:hover {
        color: rgba(13, 57, 46, 1);
    }

    #main-nav.dark {

        border-bottom: 1px solid rgba(51, 51, 51, 1);

        .logo-white {
            display: none;
        }

        .logo-black {
            display: initial;
        }

        .submenu-title-wrapper, .ant-menu-submenu {
            color: rgba(51, 51, 51, 1) !important;
        }

        .nav-link {
            color: rgba(51, 51, 51, 1) !important;
        }

        .ant-menu-item {
            padding-left: 30px;
            padding-right: 30px;
            color: rgba(51, 51, 51, 1) !important;
        }

    }

    .nav-link {
        color: #F9F9F9 !important;
    }
</style>